<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="60%"
    :before-close="() => (dialogVisible = false)"
  >
    <el-row style="padding-bottom: 10px; font-size: 15px;font-weight:700;line-height:24px">
      <el-col :span="8">
        <div>
          班级名称：
          <span
            v-if="classInfo && classInfo.className"
            v-text="classInfo.className"
          ></span>
        </div>
      </el-col>
      <el-col :span="8" v-if="classInfo">
        任课老师：
        <span v-if="classInfo" v-text="classInfo.zjName"></span>
      </el-col>
      <el-col :span="8">
        班级类型：
        <span>
          <span>{{ classInfo.type }}</span>
        </span>
      </el-col>
      <el-col :span="8">
        授课课程：
        <span v-if="classInfo && classInfo.course != null">
          <span>{{ classInfo.course.title }}</span>
        </span>
      </el-col>
       <el-col :span="8">
        开课时间：
        <span v-if="classInfo">
          <span>{{ classInfo.open_date }}</span>
        </span>
      </el-col>
    </el-row>
    <h3>排课信息：</h3>
    <el-table :size="size" :border="true" :data="lessonList">
      <el-table-column
        sortable
        align="center"
        prop="start_time"
        label="上课时间"
      />
      <el-table-column align="center" prop="task" label="课堂任务">
        <template slot-scope="scope">
          <span
            v-if="
              scope.row.courseUnitlist && scope.row.courseUnitlist.length > 0
            "
          >
            {{ scope.row.courseUnitlist[0].title }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="finished_status"
        label="排课状态"
      />
    </el-table>
    <el-pagination
      :size="size"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageInfo.pageNum"
      :page-sizes="[10, 20, 30, 50, 100]"
      :page-size="pageInfo.pageSize"
      :total="pageInfo.total"
      layout="total, sizes, prev, pager, next, jumper"
      background
    >
    </el-pagination>
  </el-dialog>
</template>
<script>
import {
  getClassTeacherInfoById,
  getLessonInfoByClassId,
} from "@/api/senate/class";
export default {
  data() {
    return {
      size: "small",
      dialogVisible: false,
      classInfo: {},
      lessonList: [],
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        needCount: true,
      },
      originInfo:{},
    };
  },
  methods: {
    initData(val) {
      this.originInfo = val;
      this.$nextTick(() => {
        getClassTeacherInfoById({ class_id: val.class_id }).then((res) => {
          this.classInfo = res.body;
        });
        this.getListHandler();
      });
    },
    /**
     * 获取排课
     */
    getListHandler() {
      const params = {
        pageNum: this.pageInfo.pageNum,
        pageSize: this.pageInfo.pageSize,
        class_id: this.originInfo.class_id,
        isDesc: false,
      };
      getLessonInfoByClassId(params).then((res) => {
        this.lessonList = res.body.list;
        this.pageInfo.total = res.body.total;
      });
    },
    /**
     * 页码改变
     */
    handleCurrentChange(page) {
      this.pageInfo.pageNum = page;
      this.getListHandler();
    },
    /**
     * pageSize改变
     */
    handleSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.getListHandler();
    },
  },
};
</script>
<style lang="scss" scoped>

/deep/ .el-dialog__body{
  padding: 0 30px 20px !important;
}
</style>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        width: "60%",
        "before-close": () => (_vm.dialogVisible = false),
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-row",
        {
          staticStyle: {
            "padding-bottom": "10px",
            "font-size": "15px",
            "font-weight": "700",
            "line-height": "24px",
          },
        },
        [
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", [
              _vm._v(" 班级名称： "),
              _vm.classInfo && _vm.classInfo.className
                ? _c("span", {
                    domProps: { textContent: _vm._s(_vm.classInfo.className) },
                  })
                : _vm._e(),
            ]),
          ]),
          _vm.classInfo
            ? _c("el-col", { attrs: { span: 8 } }, [
                _vm._v(" 任课老师： "),
                _vm.classInfo
                  ? _c("span", {
                      domProps: { textContent: _vm._s(_vm.classInfo.zjName) },
                    })
                  : _vm._e(),
              ])
            : _vm._e(),
          _c("el-col", { attrs: { span: 8 } }, [
            _vm._v(" 班级类型： "),
            _c("span", [_c("span", [_vm._v(_vm._s(_vm.classInfo.type))])]),
          ]),
          _c("el-col", { attrs: { span: 8 } }, [
            _vm._v(" 授课课程： "),
            _vm.classInfo && _vm.classInfo.course != null
              ? _c("span", [
                  _c("span", [_vm._v(_vm._s(_vm.classInfo.course.title))]),
                ])
              : _vm._e(),
          ]),
          _c("el-col", { attrs: { span: 8 } }, [
            _vm._v(" 开课时间： "),
            _vm.classInfo
              ? _c("span", [
                  _c("span", [_vm._v(_vm._s(_vm.classInfo.open_date))]),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
      _c("h3", [_vm._v("排课信息：")]),
      _c(
        "el-table",
        { attrs: { size: _vm.size, border: true, data: _vm.lessonList } },
        [
          _c("el-table-column", {
            attrs: {
              sortable: "",
              align: "center",
              prop: "start_time",
              label: "上课时间",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", prop: "task", label: "课堂任务" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.courseUnitlist &&
                    scope.row.courseUnitlist.length > 0
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(scope.row.courseUnitlist[0].title) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "finished_status",
              label: "排课状态",
            },
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          size: _vm.size,
          "current-page": _vm.pageInfo.pageNum,
          "page-sizes": [10, 20, 30, 50, 100],
          "page-size": _vm.pageInfo.pageSize,
          total: _vm.pageInfo.total,
          layout: "total, sizes, prev, pager, next, jumper",
          background: "",
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.show,
            "before-close": _vm.close,
            "close-on-click-modal": false,
            width: "60%",
            top: "5vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.show = $event
            },
            open: _vm.openDialog,
          },
        },
        [
          _c(
            "div",
            { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
            [
              _c("i"),
              _c("span", {
                domProps: { textContent: _vm._s(_vm.dialogTitle) },
              }),
            ]
          ),
          _c(
            "el-form",
            {
              ref: "formData",
              attrs: {
                model: _vm.formData,
                rules: _vm.rules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "班级名称：", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入班级名称", clearable: "" },
                    on: {
                      input: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                    model: {
                      value: _vm.formData.name,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formData,
                          "name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formData.name",
                    },
                  }),
                ],
                1
              ),
              _vm.classInfo === null
                ? _c(
                    "el-form-item",
                    { attrs: { label: "授课课程：", prop: "course_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: "请选择收费课程",
                            clearable: "",
                            filterable: "",
                          },
                          on: { change: _vm.handleChange },
                          model: {
                            value: _vm.formData.course_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "course_id", $$v)
                            },
                            expression: "formData.course_id",
                          },
                        },
                        _vm._l(_vm.ProductList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.title, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.normalCourse == 1 && _vm.classInfo == null
                ? _c(
                    "div",
                    { staticStyle: { margin: "0 0 20px 120px" } },
                    [
                      _vm._l(_vm.teacherClassList, function (i, j) {
                        return [
                          _c(
                            "el-button",
                            {
                              key: j,
                              attrs: { size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.openClassDetail(i)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(i.name) + " ")]
                          ),
                        ]
                      }),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.normalCourse === 1 && _vm.classInfo === null
                ? [
                    _c(
                      "el-form-item",
                      { attrs: { label: "双师班级：", prop: "class_id" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              placeholder: "请选择双师班级",
                              clearable: "",
                              filterable: "",
                            },
                            on: { change: _vm.handleChangeClass },
                            model: {
                              value: _vm.formData.class_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "class_id", $$v)
                              },
                              expression: "formData.class_id",
                            },
                          },
                          _vm._l(_vm.teacherClassList, function (item) {
                            return _c("el-option", {
                              key: item.class_id,
                              attrs: { label: item.name, value: item.class_id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "选择学员：", prop: "studentIds" } },
                      [
                        _vm.classStudentList.length === 0
                          ? [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "text",
                                    icon: "el-icon-s-operation",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.seleContainer("student")
                                    },
                                  },
                                },
                                [_vm._v("选择学员")]
                              ),
                            ]
                          : _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: { multiple: "", clearable: "" },
                                on: {
                                  clear: function ($event) {
                                    return _vm.removeTag("student", true)
                                  },
                                  "remove-tag": function ($event) {
                                    return _vm.removeTag("student")
                                  },
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.seleContainer("student")
                                  },
                                },
                                model: {
                                  value: _vm.formData.studentIds,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "studentIds", $$v)
                                  },
                                  expression: "formData.studentIds",
                                },
                              },
                              _vm._l(_vm.classStudentList, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }),
                              1
                            ),
                      ],
                      2
                    ),
                  ]
                : _vm._e(),
              _vm.normalCourse === 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "开班日期：", prop: "open_date" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "date",
                          placeholder: "选择开班日期",
                          "value-format": "yyyy-MM-dd",
                        },
                        model: {
                          value: _vm.formData.open_date,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "open_date", $$v)
                          },
                          expression: "formData.open_date",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.classInfo === null && _vm.normalCourse === 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "上课老师：", prop: "teacherIds" } },
                    [
                      _vm.classTeacherList.length === 0
                        ? [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  icon: "el-icon-s-operation",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.seleContainer("teacher")
                                  },
                                },
                              },
                              [_vm._v("选择老师")]
                            ),
                          ]
                        : _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { multiple: "", clearable: "" },
                              on: {
                                clear: function ($event) {
                                  return _vm.removeTag("teacher", true)
                                },
                                "remove-tag": function ($event) {
                                  return _vm.removeTag("teacher")
                                },
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.seleContainer("teacher")
                                },
                              },
                              model: {
                                value: _vm.formData.teacherIds,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "teacherIds", $$v)
                                },
                                expression: "formData.teacherIds",
                              },
                            },
                            _vm._l(_vm.classTeacherList, function (item) {
                              return _c("el-option", {
                                key: item.userId,
                                attrs: { label: item.name, value: item.userId },
                              })
                            }),
                            1
                          ),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            2
          ),
          _c(
            "el-row",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  staticClass: "dialog-btn1",
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.confirm()
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "dialog-btn",
                  attrs: { size: "small", type: "info" },
                  on: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
          _c("sele-container", {
            attrs: {
              show: _vm.containerDialog,
              type: _vm.containerType,
              checkData: _vm.checkData,
              courseId: _vm.courseId,
            },
            on: {
              close: function ($event) {
                _vm.containerDialog = false
              },
              seleContainerData: _vm.seleContainerData,
            },
          }),
        ],
        1
      ),
      _c("DoubleDetail", { ref: "doubleFlag" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
<div>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="60%"
    @open="openDialog"
    top="5vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span v-text="dialogTitle"></span>
    </div>
    <el-form
      ref="formData"
      :model="formData"
      :rules="rules"
      label-width="120px"
    >
      <el-form-item label="班级名称：" prop="name">
        <el-input
          v-model.trim="formData.name"
          placeholder="请输入班级名称"
          clearable
          @input="$forceUpdate()"
        ></el-input>
      </el-form-item>
      <el-form-item label="授课课程：" prop="course_id" v-if="classInfo === null">
        <el-select
          style="width:100%"
          v-model="formData.course_id"
          placeholder="请选择收费课程"
          clearable
          filterable
          @change="handleChange"
        >
          <el-option
            v-for="item in ProductList"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
       <div v-if="normalCourse == 1 && classInfo ==  null" style="margin:0 0 20px 120px">
         <template  v-for="(i,j) in teacherClassList ">
            <el-button size="small" :key="j" @click="openClassDetail(i)">
              {{i.name}}
            </el-button>
         </template>
       </div>
      <template  v-if="normalCourse === 1 && classInfo === null">
         <el-form-item label="双师班级：" prop="class_id" >
        <el-select
          style="width:100%"
          v-model="formData.class_id"
          placeholder="请选择双师班级"
          clearable
          filterable
          @change="handleChangeClass"
        >
          <el-option
            v-for="item in teacherClassList"
            :key="item.class_id"
            :label="item.name"
            :value="item.class_id"
          >
          </el-option>
        </el-select>

      </el-form-item>
         <el-form-item
        label="选择学员："
        prop="studentIds"
      >

        <template v-if="classStudentList.length === 0">
          <el-button
            type="text"
            icon="el-icon-s-operation"
            @click="seleContainer('student')"
            >选择学员</el-button
          >
        </template>
        <el-select
          v-model="formData.studentIds"
          style="width:100%"
          multiple
          v-else
          clearable
          @clear="removeTag('student', true)"
          @remove-tag="removeTag('student')"
          @click.native="seleContainer('student')"
        >
          <el-option
            v-for="item in classStudentList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      </template>
      <el-form-item label="开班日期：" prop="open_date" v-if="normalCourse === 2">
        <el-date-picker
          style="width:100%"
          v-model="formData.open_date"
          type="date"
          placeholder="选择开班日期"
          value-format="yyyy-MM-dd"
        ></el-date-picker>
      </el-form-item>
      <el-form-item
        label="上课老师："
        prop="teacherIds"
        v-if="classInfo === null && normalCourse  === 2"
      >
        <template v-if="classTeacherList.length === 0">
          <el-button
            type="text"
            icon="el-icon-s-operation"
            @click="seleContainer('teacher')"
            >选择老师</el-button
          >
        </template>
        <el-select
          style="width:100%"
          v-model="formData.teacherIds"
          multiple
          v-else
          clearable
          @clear="removeTag('teacher', true)"
          @remove-tag="removeTag('teacher')"
          @click.native="seleContainer('teacher')"
        >
          <el-option
            v-for="item in classTeacherList"
            :key="item.userId"
            :label="item.name"
            :value="item.userId"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <!-- <el-divider></el-divider> -->
    <el-row slot="footer">
      <el-button type="primary" @click="confirm()" size="small" class="dialog-btn1"
        >确 定</el-button
      >
      <el-button @click="close()" size="small" type="info" class="dialog-btn">取 消</el-button>
    </el-row>

    <!-- sele-container -->
    <sele-container
      :show="containerDialog"
      :type="containerType"
      :checkData="checkData"
      :courseId="courseId"
      @close="containerDialog = false"
      @seleContainerData="seleContainerData"
    ></sele-container>

  </el-dialog>
    <!-- 课程详情 -->
     <DoubleDetail ref="doubleFlag"/>
</div>

</template>

<script>

  import SeleContainer from '@/components/merchantClass/sele-container'
  import {getDoubleClaByCourseId } from '@/api/merchantClass'
  import {getListCourseOnly as getCourseListByMerchant} from '@/api/businesscourse/course'
  import DoubleDetail from '@/components/merchantClass/double-detail'
  export default {
    components: { SeleContainer,DoubleDetail },
    props: {
      classInfo: {
        default: null,
        type: Object,
      },
      ordinaryClass: {
        default: false,
        type: Boolean,
      },
      show: {
        default: false,
        type: Boolean,
      },
    },
    watch: {
      show() {
        if (this.show) {
          if (this.$refs.formData) {
            this.$refs.formData.resetFields()
          }
        }
      },
    },
    data() {
      const validTeacher = (rule, value, callback) => {
        if (!value || value.length === 0) {
          return callback(new Error('请选择上课老师'))
        } else {
          callback()
        }
      }
      return {
        normalCourse:0,
        dialogTitle: '',
        formData: {
          lctype:'',
          classType:''
        },
        classTeacherList: [], // 老师数据
        classAssistantList: [], // 助教数据
        classStudentList: [], // 助教数据
        courseId:'',
        checkData: [],
        ProductList: [], // 课程项目
        doubleInfo:{},//双师班级信息
        teacherClassList:[],
        isMultiple: false, // 是否多选
        rules: {
          name: [
            { required: true, message: '请输入班级名称', trigger: 'blur' },
          ],
          open_date: [
            { required: true, message: '请选择开班日期', trigger: 'change' },
          ],
          course_id: [
            { required: true, message: '请选择所属课程', trigger: 'change' },
          ],
          class_id: [
            { required: true, message: '请选择关联班级', trigger: 'change' },
          ],
          teacherIds: [
            { required: true, validator: validTeacher, trigger: 'blur' },
          ],
        },
        containerDialog: false,
        containerType: '',
      }
    },
    methods: {
      //打开班级详情
      openClassDetail(val){
        this.$refs.doubleFlag.dialogVisible = true;
        this.$refs.doubleFlag.initData(val);
      },
      handleChangeClass(e){
        // console.log(this.teacherClassList,e);
        this.doubleInfo =this.teacherClassList.filter(i=>i.class_id==e)[0];
        // this.$refs.doubleFlag.initData(this.teacherClassList.filter(i=>i.class_id==e)[0]);
      },
      handleChange() {
        this.ProductList.forEach(item => {
          if(this.formData.course_id === item.id) {
            if(item.type === '常规') {
              this.formData.classType = '常规'
              this.normalCourse = 2
            }else if(item.type === '双师'){
              this.getDoubleClaByCourseId(item.id)
              this.formData.classType = '双师'
              this.normalCourse = 1;
              this.courseId = item.id;
            }else if(item.type === '视频'){
              this.formData.classType = '视频'
              this.normalCourse = 2;
            }
          }
        })
      },
      openDialog() {
        this.normalCourse = 0
        this.formData = {
          lctype:'常规',
          type:'常规',
          classType:'',
        }
        this.classTeacherList = []
        this.classAssistantList = []
        this.classStudentList = []
        this.getCourseListByMerchant()
        if (this.classInfo === null) {
          this.dialogTitle = '新增班级'
        } else {
          this.dialogTitle = '编辑班级'
          this.formData = Object.assign({}, this.classInfo)
          this.formData.course_id = this.classInfo.course.id
          if(this.classInfo.course.type === '常规') {
            this.formData.classType = '常规'
            this.formData.name = this.formData.className
            this.normalCourse = 2
          }else if(this.classInfo.course.type === '双师') {
            this.getDoubleClaByCourseId(this.classInfo.course.id)
            this.formData.class_id = this.$route.query.classID
            this.formData.classType = '双师'
            this.normalCourse = 1
          }else if(this.classInfo.course.type === '视频'){
            this.formData.classType = '视频'
            this.formData.name = this.formData.className
            this.normalCourse = 2
          }
          this.formData.name = this.classInfo.className
          if (
            this.classInfo.course !== null &&
            this.classInfo.course !== undefined
          ) {
            this.formData.catalogId = this.classInfo.course.id
            // this.hasBindCourse = this.classInfo.course.id
          } else {
            // this.hasBindCourse = ''
          }
        }
      },

      // 获取收费课程
      async getCourseListByMerchant() {
        const res = await getCourseListByMerchant({
          pageNum:1,
          pageSize:1000,
          needCount:true,
        });
        console.log(res.body.list);
        this.ProductList = res.body.list
      },

      // 获取双师课程关联的班级
      async getDoubleClaByCourseId(course_id) {
        const res = await getDoubleClaByCourseId({
          courseId:course_id
        })
        this.teacherClassList = res.body
      },


      // 选择要加载的数据类型
      seleContainer(type) {
        this.checkData = [];

        if ( type=='teacher' &&this.classTeacherList.length !== 0) {
          this.checkData = this.classTeacherList
        }
        if (type=='student' && this.classStudentList.length !== 0) {
          this.checkData = this.classStudentList
        }

        this.containerType = type
        this.isMultiple = false
        this.containerDialog = true
      },
      // 所选中的数据
      seleContainerData(data, type) {
        if(type=='teacher'){
          this.classTeacherList = [...data]
          this.formData.teacherIds = [data[0].userId]
          this.$refs.formData.validateField('teacherIds')
        }else if(type=='student'){
          this.classStudentList = [...data]
          this.formData.studentIds = [...data.map(i=>i.id)]
          this.$refs.formData.validateField('studentIds')
        }
      },

      // 移除数据函数
      // argument type 类型, empty 是否清空数据
      removeTag(type) {
        if (type === 'room') {
          this.classroomList = []
          this.formData.classroom_id = null
        } else if (type === 'teacher') {
          this.classTeacherList = []
          this.formData.teacherIds = null
        } else if (type === 'assistant') {
          this.classAssistantList = []
          this.formData.assistantIds = null
        } else if (type === 'student') {
          this.classStudentList = []
          this.formData.studentIds = null
        }
      },

      removeAssistant(val) {
        this.classAssistantList.forEach((item, index) => {
          if (item.userId === val) {
            this.classAssistantList.splice(index, 1)
          }
        })
      },

      close() {
        this.$emit('close')
      },

      confirm() {
        this.$refs.formData.validate(valid => {
          if (valid) {
            if (this.classInfo === null) {
              this.$emit('addClassForm', this.formData)
            } else {
              this.$emit('editClassForm', this.formData)
            }
            this.close()
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
@import '~@/style/dialog.scss';
.dialog-btn1 {
  width: 200px;
  height: 50px;
  margin-bottom: 38px;
  &:first-child {
    margin-left: 220px;
    margin-right: 38px;
  }
}
</style>
